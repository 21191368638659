<template>
    <section class="container-fluid d-flex flex-column flex-lg-row justify-content-between mb-1">
        <slot name="title">
            <h4 class="d-block">{{ title }}</h4>
        </slot>
        <slot name="data">
            <template v-if="itemCount ||totalPrice">
              <div class="d-flex flex-column flex-lg-row">
                  <template v-if="itemCount">
                    <h4 class="d-block mr-2">Всего: {{ itemCount }}</h4>
                  </template>
                  <template v-if="totalPrice">
                    <h4 class="d-block">Выручка: {{ totalPrice }}</h4>
                  </template>
              </div>
            </template>
        </slot>
    </section>
</template>

<script>
export default {
  name: 'OrdersTotal',
  props: {
    itemCount: {
      type: [String, Number],
    },
    totalPrice: {
      type: [String, Number],
    },
    title: {
      type: String,
      default() {
        return 'Заказы'
      },
    },
  },
}
</script>
