var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-row',{staticClass:"p-1"},[_c('b-col',{staticClass:"d-flex align-center",attrs:{"md":"1"}},[(_vm.pageSize)?[_c('b-form-select',_vm._b({staticStyle:{"min-width":"70px"},on:{"change":_vm.onPageSize},model:{value:(_vm.page_size),callback:function ($$v) {_vm.page_size=$$v},expression:"page_size"}},'b-form-select',{
            options: _vm.options
          },false))]:_vm._e(),(_vm.isColumnSettings)?[_c('b-button',_vm._b({on:{"click":_vm.onSettingsModal}},'b-button',{
            title: 'Настройки списка',
            variant: 'text',
            size: 'sm',
          },false),[_c('feather-icon',{attrs:{"icon":"SettingsIcon","size":"22"}})],1)]:_vm._e()],2)],1),_vm._t("default"),(_vm.page_size)?[_c('b-row',{staticClass:"p-1"},[(_vm.itemCount)?[_c('b-col',{staticClass:"d-none d-md-flex"},[_c('p',{staticClass:"pl-1"},[_vm._v(" c "+_vm._s(_vm.page === 1 ? 1 : _vm.page * _vm.pageSize - _vm.pageSize)+" по "+_vm._s(_vm.page === 1 && _vm.pageSize * _vm.page > _vm.itemCount ? _vm.itemCount : _vm.pageSize * _vm.page > _vm.itemCount ? _vm.itemCount : _vm.pageSize * _vm.page)+" из "+_vm._s(_vm.itemCount)+" ")])])]:_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-center justify-content-md-end"},[_c('b-pagination',_vm._b({staticClass:"pr-1",attrs:{"prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.onChangePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},'b-pagination',{
            totalRows: _vm.pageCount * _vm.pageSize,
            perPage: _vm.pageSize,
            firstNumber: true,
            lastNumber: true,
         },false))],1)],2)]:_vm._e(),_c('b-modal',_vm._b({on:{"ok":_vm.onSettingsConfirm}},'b-modal',{
      id: _vm.settingsModalId,
      title: 'Настройки списка',
      centered: true,
      'ok-only': true,
    },false),[_c('column-select',_vm._b({on:{"update:settings":_vm.onSettings}},'column-select',{
        items: _vm.fields
      },false))],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }