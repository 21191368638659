<template>
  <div class="contaner">
    <div class="row">
      <div class="col-md-6 col-sm-12 mb-1">
        <b-form-checkbox
          :checked="toggleAll"
          @change="onAllCheck"
        >Все</b-form-checkbox>
      </div>
    </div>
    <div class="row">
      <template v-for="(item, index) in items">
        <template v-if="!item.hidden">
          <div
            class="col-md-6 col-sm-12 mb-1"
            :key="index"
          >
            <b-form-checkbox
              :checked="fields[index].status ? true : false"
              @change="onCheck({ status: $event ? true : false, key: item.key })"
            >{{ item.label || item.name }}</b-form-checkbox>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColumnSelect',

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      fields: this.items,
      toggleAll: this.toggleAllSetup(),
    }
  },
  methods: {
    onCheck({ status, key }) {
      for (const prop in this.fields) {
        if (this.fields.hasOwnProperty(prop) && this.fields[prop].key === key) {
          this.fields[prop].status = status
          this.setClass({ status, prop })
        }
      }
      if (!status) this.toggleAll = false
      this.$emit('update:settings', { settings: this.fields })
    },
    onAllCheck(status) {
      for (const prop in this.fields) {
        if (this.fields.hasOwnProperty(prop) && !this.fields[prop].notActive) {
          this.fields[prop].status = status
          this.setClass({ status, prop })
        }
      }
      this.toggleAll = !this.toggleAll
      this.$emit('update:settings', { settings: this.fields })
    },
    setClass({ status, prop }) {
      if (!status) {
        this.fields[prop].thClass = 'd-none'
        this.fields[prop].tdClass = 'd-none'
      } else {
        this.fields[prop].thClass = ''
        this.fields[prop].tdClass = ''
      }
    },
    toggleAllSetup() {
      if (this.items.find(item => !item.hidden && !item.status)) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
