<template>
  <b-card no-body>
    <b-row class="p-1">
      <!-- Per Page -->
      <b-col
        md="1"
        class="d-flex align-center"
      >
        <template v-if="pageSize">
          <b-form-select
            v-model="page_size"
            v-bind="{
              options
            }"
            @change="onPageSize"
            style="min-width: 70px;"
          />
        </template>
        <template v-if="isColumnSettings">
          <b-button
            v-bind="{
              title: 'Настройки списка',
              variant: 'text',
              size: 'sm',
            }"
            @click="onSettingsModal"
          >
            <feather-icon
              icon="SettingsIcon"
              size="22"
            />
          </b-button>
        </template>
      </b-col>
    </b-row>
    <slot></slot>
    <template v-if="page_size">
      <b-row class="p-1">
        <template v-if="itemCount">
          <b-col class="d-none d-md-flex">
            <p class="pl-1">
              c {{ page === 1 ? 1 : page * pageSize - pageSize }}
              по {{ page === 1  && pageSize * page > itemCount
              ? itemCount : pageSize * page > itemCount
              ? itemCount : pageSize * page
            }}
              из {{ itemCount }} </p>
          </b-col>
        </template>
        <b-col class="d-flex justify-content-center justify-content-md-end">
          <b-pagination
            class="pr-1"
            v-model="page"
            v-bind="{
              totalRows: pageCount * pageSize,
              perPage: pageSize,
              firstNumber: true,
              lastNumber: true,
           }"
            prev-class="prev-item"
            next-class="next-item"
            @change="onChangePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </template>

    <b-modal
      v-bind="{
        id: settingsModalId,
        title: 'Настройки списка',
        centered: true,
        'ok-only': true,
      }"
      @ok="onSettingsConfirm"
    >
      <column-select
        v-bind="{
          items: fields
        }"
        @update:settings="onSettings"
      ></column-select>
    </b-modal>
  </b-card>
</template>

<script>
import ColumnSelect from '@/components/common/table/ColumnSelect'

export default {
  name: 'TableBody',

  props: {
    pageSize: {
      type: Number,
      default() {
        return 10
      },
    },
    isColumnSettings: {
      type: Boolean,
      default() {
        return false
      },
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    currentPage: {
      type: Number,
      default() {
        return 1
      },
    },
    options: {
      type: Array,
      default() {
        return [
          { value: '5', text: 5 },
          { value: '10', text: 10 },
          { value: '25', text: 25 },
          { value: '50', text: 50 },
        ]
      },
    },
    pageCount: {
      type: Number,
      default() {
        return 0
      },
    },
    itemCount: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  components: {
    ColumnSelect,
  },
  data() {
    return {
      page: this.currentPage,
      page_size: this.pageSize,
      settingsModalId: 'settings-modal',
      settings: {},
    }
  },

  methods: {
    onPageSize() {
      this.$emit('update:page-size', this.page_size)
    },

    onChangePage(page) {
      this.$emit('update:page', page )
    },

    onSettingsModal() {
      this.$bvModal.show(this.settingsModalId)
    },

    onSettings({ settings }) {
      this.settings = settings
    },

    onSettingsConfirm() {
      this.$emit('settings-confirm', { settings: this.settings })
    },
  },
}
</script>
