<template>
  <section
    class="d-flex ml-auto"
    style="width: 130px;"
  >
    <slot></slot>
    <template v-if="!hideEdit">
      <b-button
        :class="[{'mr-1': !hideDelete}, {'m-auto': hideDelete}]"
        @click="$emit('edit')"
        :variant="editConfig.variant"
        v-bind="{
          disabled
        }"
      >
        <feather-icon
          :icon="editConfig.icon"
          :size="editConfig.size"
        />
      </b-button>
    </template>
    <template v-if="!hideDelete">
      <b-button
        v-if="!hideDelete"
        @click="$emit('delete')"
        v-bind="{
          disabled
        }"
        :variant="deleteConfig.variant"
      >
        <feather-icon
          :icon="deleteConfig.icon"
          :size="deleteConfig.size"
        />
      </b-button>
    </template>
  </section>
</template>

<script>

export default {
  name: 'TableButtons',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    editConfig: {
      type: Object,
      default: () => ({
        variant: 'primary',
        icon: 'EditIcon',
        size: '1x',
      }),
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
    deleteConfig: {
      type: Object,
      default: () => ({
        variant: 'danger',
        icon: 'TrashIcon',
        size: '1x',
      }),
    },
  },
}
</script>
