export const phoneToMaskView = ({ phone }) => {
  if (phone && phone.length === 11) {
    const collection = phone.split('')
    collection.splice(0, 0, '+')
    collection.splice(2, 0, '(')
    collection.splice(6, 0, ')')
    collection.splice(7, 0, ' ')
    collection.splice(11, 0, ' ')
    collection.splice(14, 0, ' ')
    const result = collection.join('')
    return result
  }
}
