<template>
  <b-card>
    <div class="container-fluid">
      <slot v-if="!hideTitle" name="title">
        <h2>{{ $route.meta.title }}</h2>
      </slot>
      <slot name="more"></slot>
      <template v-if="$slots['subtitle']">
        <div class="row p-1">
          <slot name="subtitle"></slot>
        </div>
      </template>
      <template v-if="$slots['body']">
        <div class="row g-2">
          <slot name="body"></slot>
        </div>
      </template>
      <div class="row mt-1 mt-lg-2 d-block">
        <div class="col-12 d-flex flex-column flex-lg-row justify-content-end">
          <template v-if="!hideDecline">
            <b-button
              :variant="declineVariant"
              class="mb-1 mb-lg-0 mr-0 mr-lg-2 col-12 col-lg-2"
              @click="$emit('decline')"
            >{{ declineName }}</b-button>
          </template>
          <template v-if="!hideAdmit">
            <b-button
              :disabled='disabled'
              class="col-12 col-lg-2"
              :variant="admitVariant"
              @click="$emit('admit')"
            >{{ admitName }}</b-button>
          </template>
        </div>
      </div>
      <slot name="modal"></slot>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'Card',

  props: {
    hideDecline: {
      type: Boolean,
      default: false,
    },
    hideAdmit: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    declineName: {
      type: String,
      default: 'Отмена',
    },
    admitName: {
      type: String,
      default: 'Сохранить',
    },
    declineVariant: {
      type: String,
      default: 'warning',
      validator: function(value) {
        return ['success', 'warning', 'danger'].indexOf(value) !== -1
      },
    },
    admitVariant: {
      type: String,
      default: 'success',
      validator: function(value) {
        return ['success', 'warning', 'danger'].indexOf(value) !== -1
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
}
</script>
