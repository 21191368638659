const statusCollection = Object.freeze([
  { text: 'Все', value: '' },
  { text: 'Активен', value: 1 },
  { text: 'Не активен', value: 0 },
])

const orderPaymentStatusCollection = Object.freeze([
  { text: 'Оплачено', value: 1 },
  { text: 'Оплатит позже на Cloud payments', value: 2 },
  { text: 'Оплатит позже на карту', value: 3 },
  { text: 'Оплата при получении', value: 4 },
])

const orderStatusCollection = Object.freeze([
  { text: 'Новый', value: 1, variant: 'secondary', status: 'new' },
  { text: 'Собранный', value: 2, variant: 'success', status: 'assembled' },
  { text: 'У курьера', value: 3, variant: 'info', status: 'taken_by_courier' },
  { text: 'Выполненный', value: 4, variant: 'warning', status: 'completed' },
  { text: 'Выполнен и подтвержден', value: 5 },
])

const deliveryTypeCollection = Object.freeze([
  { text: 'Самовывоз', value: 0 },
  { text: 'Своя', value: 1 },
  { text: 'Доставка', value: 2 },
])

const OrderStatus = Object.freeze({
  1: 'Новый',
  2: 'Собранный',
  3: 'У курьера',
  4: 'Выполненный',
  5: 'Выполнен и подтвержден',
  6: 'Удален',
})

const PayStatus = Object.freeze({
  1: 'Оплачен',
  2: 'Не оплачен',
})

const PermissionList = Object.freeze({
  analyticAccess: 'analyticAccess',
  clientsAccess: 'clientsAccess',
  ordersAccess: 'ordersAccess',
  ordersCalendarAccess: 'ordersCalendarAccess',
  staffAccess: 'staffAccess',
})

export { PermissionList, statusCollection, orderPaymentStatusCollection, orderStatusCollection, deliveryTypeCollection, PayStatus, OrderStatus }
